import React from 'react';
import '../MainContent.css';

const AboutPage: React.FC = () => {
  return (
    <main className="main-content" style={{ backgroundImage: 'url(/kotelnya.png)' }}>
	<div className="skewed-background"></div>
      <div className="company-info">
        <h1>Абонентам</h1>
        <p>
        Уважаемые жители!
        <br/>
В связи с заключением прямых договоров о предоставлении коммунальных услуг с "Жилэнерго +", которые заключаются непосредственно с каждым собственником и пользователем помещений в многоквартирном доме, сообщаем следующее:
<br/>
1. Прямые договоры, содержащие условия о предоставлении коммунальных услуг, заключаются на неопределенный срок в соответствии с Типовым договором, размещенном на нашем сайте.
<br/>
2. Для вашего удобства мы разработали систему, обеспечивающую простоту и комфорт при взаимодействии с "Жилэнерго +":
<br/><br/>
   - Зарегистрируйтесь на сайте "Жилэнерго +" для получения доступа к личному кабинету.<br/>
   - Личный кабинет "Жилэнерго +" интегрирован с личным кабинетом собственника жилья на сайте "Домокей".<br/>
   - Используйте мобильное приложение "Домокей" для максимально удобной и быстрой передачи показаний счетчиков и оплаты квитанций.<br/>
   <br/>
3. После регистрации вы сможете:
<br/>
   - Передавать показания приборов учета<br/>
   - Получать квитанции напрямую от "Жилэнерго +"<br/>
   - Производить оплату услуг "Жилэнерго +" через приложение "Домокей" в телефоне<br/>
   - Быстро оплачивать услуги "Жилэнерго +" любой банковской картой на сайте<br/>
   - Отслеживать тарифы и расходы за коммунальные платежи<br/>
   - Просматривать архив квитанций и график начислений<br/>
   - Контролировать состояние приборов учета и историю показаний<br/>
   <br/>
Важно: Заключение договоров в письменной форме не требуется! (ч.6 ст. 157.2 ЖК РФ)
<br/>
"Жилэнерго +" стремится сделать процесс взаимодействия с нами максимально удобным и эффективным. Мы ценим ваше время и комфорт, поэтому предлагаем современные решения для управления вашими коммунальными услугами.
        </p>
      </div>
    </main>
  );
};

export default AboutPage;
