// AboutPage.tsx
import React from 'react';
import '../MainContent.css';

const AboutPage: React.FC = () => {
  return (
    <main className="main-content" style={{ backgroundImage: 'url(/kotelnya.png)' }}>
	<div className="skewed-background"></div>
      <div className="company-info">
        <h1>О Компании</h1>
        <p>
        О компании "Жилэнерго +"<br/>

"Жилэнерго +" является независимым оператором в сфере теплоснабжения на территории города Химки Московской области. Наша команда опытных специалистов обеспечивает профессиональную и надежную эксплуатацию коммунальной инфраструктуры, включая 8 крышных котельных, находящихся в нашей собственности, гарантируя надежное и бесперебойное теплоснабжение потребителей. Крышные котельные, которые эксплуатирует "Жилэнерго +", представляют собой современное и эффективное решение для обеспечения теплом и горячей водой многоквартирных домов и других объектов. Эти установки выполняют следующие основные функции:
<br/><br/>
1. Производство тепловой энергии для отопления помещений<br/>
2. Обеспечение горячего водоснабжения<br/>
3. Поддержание оптимального температурного режима в здании<br/>
<br/>
Использование крышных котельных имеет ряд преимуществ, включая энергоэффективность, отсутствие необходимости в длинных теплотрассах, а также возможность индивидуальной настройки параметров теплоснабжения для каждого объекта. "Жилэнерго +" обеспечивает бесперебойную работу этих важных объектов коммунальной инфраструктуры, гарантируя комфорт и уют в домах наших потребителей.
        </p>
      </div>
    </main>
  );
};

export default AboutPage;
