import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.css';
import { API_ENDPOINTS } from '../config';
import { useNavigate } from 'react-router-dom';

interface User {
  full_name: string;
  address: string;
  email: string;
  phone: string;
  device_number: string;
  egrn: string;
  passport: string;
  calibration_certificate: string;
}

const Dashboard: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [modalImage, setModalImage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token) {
          navigate('/login');
          return;
        }

        const response = await axios.get(API_ENDPOINTS.getUser, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setUser(response.data);
      } catch (error) {
        console.error('Ошибка при загрузке данных пользователя:', error);
        navigate('/login');
      }
    };

    fetchData();
  }, [navigate]);

  const handleDocumentClick = (fileUrl: string) => {
    if (fileUrl.endsWith('.pdf')) {
      window.open(fileUrl, '_blank');
    } else {
      setModalImage(fileUrl);
    }
  };

  const renderDocument = (fileUrl: string) => {
    const isPdf = fileUrl.endsWith('.pdf');
    return (
      <div onClick={() => handleDocumentClick(fileUrl)} className="document-thumbnail">
        {isPdf ? (
          <FontAwesomeIcon icon={faFilePdf} size="3x" />
        ) : (
          <img src={fileUrl} alt="Document thumbnail" />
        )}
      </div>
    );
  };

  if (!user) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="dashboard-container">
      <div className="user-info">
        <div className="header-container">
          <h2>{user.full_name}</h2>
          <a href="https://domokey.ru/" className="pay-button" target="_blank" rel="noopener noreferrer">
            Оплатить счета
          </a>
        </div>
        <hr />
        <div className="info-block">
          <p className="label">Адрес:</p>
          <p className="value">{user.address}</p>
        </div>
        <div className="info-block">
          <p className="label">Email:</p>
          <p className="value">{user.email}</p>
        </div>
        <div className="info-block">
          <p className="label">Телефон:</p>
          <p className="value">{user.phone}</p>
        </div>
        <div className="info-block">
          <p className="label">Номер прибора учета:</p>
          <p className="value">{user.device_number}</p>
        </div>
        <div className="user-documents">
          <div>
            <p className="label">Выписка из ЕГРН:</p>
            {renderDocument(`https://zhilenergoplus.ru/uploads/${user.egrn}`)}
          </div>
          <div>
            <p className="label">Паспорт:</p>
            {renderDocument(`https://zhilenergoplus.ru/uploads/${user.passport}`)}
          </div>
          <div>
            <p className="label">Свидетельство о поверке:</p>
            {renderDocument(`https://zhilenergoplus.ru/uploads/${user.calibration_certificate}`)}
          </div>
        </div>
      </div>
  
      {modalImage && (
        <div className="modal" onClick={() => setModalImage(null)}>
          <img src={modalImage} alt="Модальное изображение" className="modal-content" />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
