import React from 'react';
import './MainContent.css';

interface MainContentProps {
  showContent: boolean;
}

const MainContent: React.FC<MainContentProps> = ({ showContent }) => {
  return (
    <main className="main-content" style={{ backgroundImage: 'url(/kotelnya.png)' }}>
      <div className="skewed-background"></div>
      {showContent && (
        <div className="company-info">
          <h1>ЖИЛЭНЕРГО +</h1>
          <p>
            ООО «Городская ресурсоснабжающая компания» является независимым оператором в сфере теплоснабжения, водоснабжения и водоотведения на территории города Москвы и Московской области.
            <br /><br />
            Совокупная мощность объектов теплоснабжения, водоснабжения и водоотведения, находящихся в управлении ООО «ГРК» по состоянию на 2022 год превышает 7 тыс. куб.м/сут. и 15 МВТ тепловой мощности. Команда опытных специалистов позволяет обеспечить качественную и надежную эксплуатацию коммунальной инфраструктуры, гарантировать надежное и бесперебойное теплоснабжение, водоснабжение и водоотведение потребителей. Качество оказываемых услуг соответствует требованиям системы менеджмента качества стандарта ГОСТ Р ИСО 9001-2015 (ISO 9001:2015).
          </p>
        </div>
      )}
    </main>
  );
};

export default MainContent;
