import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';

interface HeaderProps {
  onLogoutClick: () => void;
  isLoggedIn: boolean;
}

const Header: React.FC<HeaderProps> = ({ onLogoutClick, isLoggedIn }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Удаляем токен из LocalStorage
    localStorage.removeItem('token');

    // Вызываем дополнительную логику выхода, если она есть
    onLogoutClick();

    // Перенаправляем пользователя на страницу входа
    navigate('/login');
  };

  return (
    <header className="header">
      <div className="header-top">
        <img src="/logo.png" alt="Логотип" className="logo" />
        <div className="header-right">
          <div className="contact">
            <span>Телефон:</span>
            <span>+7 (498) 683-66-55</span>
          </div>
          <div className="buttons">
            {isLoggedIn ? (
              <>
                <Link to="/dashboard">
                  <button className="dashboard-btn">Личный кабинет</button>
                </Link>
                <button className="logout-btn" onClick={handleLogout}>Выйти</button>
              </>
            ) : (
              <>
                <Link to="/register">
                  <button className="register-btn">Зарегистрироваться</button>
                </Link>
                <Link to="/login">
                  <button className="login-btn">Войти</button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
