import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import MainContent from './components/MainContent';
import Dashboard from './components/Dashboard';
import LoginForm from './components/LoginForm';
import RegistrationForm from './components/RegistrationForm';
import Layout from './components/Layout';
import AboutPage from './components/menu/AboutPage';
import TariffsPage from './components/menu/TariffsPage';
import SubscribersPage from './components/menu/SubscribersPage';
import ContactsPage from './components/menu/ContactsPage';
import NewPassword from './components/NewPassword';
import ResetPassword from './components/ResetPassword';
import NewsPage from './components/menu/NewsPage';
import NewsDetail from './components/menu/NewsDetail';
import AddNewsPage from './components/menu/AddNewsPage'; // Импортируем новый компонент
import './App.css';

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogoutClick = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <Layout isLoggedIn={isLoggedIn} onLogoutClick={handleLogoutClick}>
      <Routes>
        <Route path="/" element={<MainContent showContent={true} />} />
        <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/" />} />
        <Route path="/register" element={<RegistrationForm />} />
        <Route path="/login" element={<LoginForm setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/news" element={<NewsPage />} />  {/* Маршрут для страницы новостей */}
        <Route path="/news/:id" element={<NewsDetail />} />  {/* Маршрут для конкретной новости */}
        <Route path="/news/add" element={isLoggedIn ? <AddNewsPage /> : <Navigate to="/login" />} />  {/* Маршрут для создания новой новости */}
        <Route path="/tariffs" element={<TariffsPage />} />
        <Route path="/subscribers" element={<SubscribersPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/reset-password/new" element={<NewPassword />} />
      </Routes>
    </Layout>
  );
};

export default App;
