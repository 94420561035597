import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Menu from './menu/Menu';
import './Layout.css';

interface LayoutProps {
  children: React.ReactNode;
  isLoggedIn: boolean;
  onLogoutClick: () => void;
}

const Layout: React.FC<LayoutProps> = ({ children, isLoggedIn, onLogoutClick }) => {
  return (
    <div className="layout">
      <Header onLogoutClick={onLogoutClick} isLoggedIn={isLoggedIn} />
      <Menu />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
