import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <img src="/logo.png" alt="Логотип" className="logo" />
      <div className="contact-info">
        <p>Телефон: +7 (498) 683-66-55</p>
        <p>Адрес: Московская область, г. Химки, Набережный проезд, д. 27, 2 этаж, кабинет 1</p>
        <p>E-mail: info@zhilenergoplus.ru</p>
      </div>
    </footer>
  );
};

export default Footer;
