import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../config';
import './AddNewsPage.css'; // Подключаем CSS

const AddNewsPage: React.FC = () => {
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [previewImage, setPreviewImage] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!title || !content || !previewImage) {
      setErrorMessage('Все поля обязательны для заполнения');
      return;
    }
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);

      if (previewImage) {
        formData.append('image', previewImage);
      }

      await axios.post(API_ENDPOINTS.createNews, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      navigate('/news');
    } catch (err: any) {
      console.error('Ошибка при создании новости:', err);
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean'],                                         // remove formatting button
        ['link', 'image']                                  // link and image, now including image upload
      ],
    },
  };

  const handleContentChange = (value: string) => {
    setContent(value);
  };

  return (
    <div className="add-news-page">
      <h1>Создать новость</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <input
        type="text"
        placeholder="Заголовок"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        className="input-title"
      />
      <input
        type="file"
        onChange={(e) => setPreviewImage(e.target.files ? e.target.files[0] : null)}
        className="input-file"
      />
      <ReactQuill
        value={content}
        onChange={handleContentChange}
        modules={modules}
        className="quill-editor"
      />
      <button onClick={handleSubmit}>Создать новость</button>
    </div>
  );
};

export default AddNewsPage;
