import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../config';
import './NewsPage.css'; // Подключаем CSS

interface News {
  id: number;
  title: string;
  preview_image_url: string;
  content: string;
}

const NewsPage: React.FC = () => {
  const [newsList, setNewsList] = useState<News[]>([]);
  const [isEditor, setIsEditor] = useState<boolean>(false);
  const [newsPage, setNewsPage] = useState<number>(1);
  const [hasMoreNews, setHasMoreNews] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        
        // Запрос новостей
        const newsResponse = await axios.get(`${API_ENDPOINTS.getNews}?page=${newsPage}&limit=10`, {
          headers: token ? { Authorization: `Bearer ${token}` } : {},
        });

        if (newsResponse.data && Array.isArray(newsResponse.data)) {
          setNewsList(prevNews => [...prevNews, ...newsResponse.data]);
          setHasMoreNews(newsResponse.data.length === 10); // Если вернулось ровно 10 элементов, возможно, есть еще
        } else {
          setHasMoreNews(false);
        }

        // Проверка роли пользователя, если токен существует
        if (token) {
          try {
            const userResponse = await axios.get(API_ENDPOINTS.getUser, {
              headers: {
                Authorization: `Bearer ${token}`,
              }
            });
            const role = userResponse.data.role;
            setIsEditor(role === 'admin' || role === 'editor');
          } catch (err: any) {
            console.error("Ошибка при проверке пользователя:", err);
          }
        }

      } catch (err: any) {
        console.error("Ошибка при получении данных:", err);
      }
    };

    fetchData();
  }, [navigate, newsPage]);

  const handleLoadMore = () => {
    setNewsPage(prevPage => prevPage + 1);
  };

  const handleAddNewsClick = () => {
    navigate('/news/add');
  };

  const handleTileClick = (news: News) => {
    navigate(`/news/${news.id}`);
  };

  return (
    <div className="news-page-container">
      <h1 className="news-title">Новости</h1>
      
      {isEditor && (
        <button className="add-news-button" onClick={handleAddNewsClick}>
          Добавить новость
        </button>
      )}

      <div className="news-grid">
        {newsList.map(news => (
          <div key={news.id} className="news-item" onClick={() => handleTileClick(news)}>
            <img src={`/uploads/news_images/${news.preview_image_url}`} alt={news.title} />
            <h3>{news.title}</h3>
          </div>
        ))}
      </div>

      {hasMoreNews && (
        <button className="load-more-button" onClick={handleLoadMore}>
          Загрузить еще новости
        </button>
      )}
    </div>
  );
};

export default NewsPage;
