import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import './LoginForm.css';
import { useNavigate, Link } from 'react-router-dom';
import { API_ENDPOINTS } from '../config';

interface LoginFormProps {
  setIsLoggedIn: (value: boolean) => void;
}

const schema = yup.object().shape({
  email: yup.string().email('Неверный формат email').required('Email обязателен'),
  password: yup.string().required('Пароль обязателен'),
});

const LoginForm: React.FC<LoginFormProps> = ({ setIsLoggedIn }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  const onSubmit = async (data: { email: string; password: string }) => {
    try {
      const response = await axios.post(API_ENDPOINTS.token, {
        username: data.email,
        password: data.password,
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      const { access_token } = response.data;

      // Сохраняем токен в LocalStorage
      localStorage.setItem('token', access_token);

      // Обновляем состояние приложения, чтобы отразить успешный вход
      setIsLoggedIn(true);

      // Перенаправляем пользователя на защищенный маршрут
      navigate('/dashboard');
    } catch (error) {
      setError('Ошибка при входе. Проверьте email и пароль.');
    }
  };

  return (
    <div className="login-form-container" style={{ backgroundImage: 'url(/kotelnya.png)' }}>
      <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Email:</label>
          <input {...register('email')} />
          {errors.email && <p className="error-message">{errors.email.message}</p>}
        </div>
        <div className="password-container">
          <label>Пароль:</label>
          <Link to="/reset-password" className="password-reset-link">Забыли пароль?</Link>
        </div>
        <div className="password-input-container">
          <input type="password" {...register('password')} />
          {errors.password && <p className="error-message">{errors.password.message}</p>}
        </div>
        <button type="submit">Войти</button>
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default LoginForm;
