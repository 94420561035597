import React from 'react';
import '../MainContent.css';

const AboutPage: React.FC = () => {
  return (
    <main className="main-content" style={{ backgroundImage: 'url(/kotelnya.png)' }}>
	<div className="skewed-background"></div>
      <div className="company-info">
        <h1>Контакты</h1>
        <p>
        Телефон: +7 (498) 683-66-55
        <br/>
Адрес: Московская область, г. Химки, Набережный проезд, д. 27, 2 этаж, кабинет 1
<br/>
E-mail: info@zhilenergoplus.ru
        </p>
      </div>
    </main>
  );
};

export default AboutPage;
