// config.ts
export const API_BASE_URL = 'https://zhilenergoplus.ru/api/v1';
//export const API_BASE_URL = 'http://127.0.0.1:8001';

export const API_ENDPOINTS = {
  getUser: `${API_BASE_URL}/users/me/`,
  register: `${API_BASE_URL}/register`,
  token: `${API_BASE_URL}/token`,
  resetPassword: `${API_BASE_URL}/reset-password`,
  resetPasswordConfirm: `${API_BASE_URL}/reset-password/confirm`,

  getNews: `${API_BASE_URL}/news/`,
  getNewsById: (id: number) => `${API_BASE_URL}/news/${id}`,
  createNews: `${API_BASE_URL}/news/`,
  updateNews: (id: number) => `${API_BASE_URL}/news/${id}`,
  deleteNews: (id: number) => `${API_BASE_URL}/news/${id}`,
  uploadImage: `${API_BASE_URL}/upload-image/`, // Если планируется загружать изображения отдельно
};