import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config';
import './NewsDetail.css'; // Подключаем CSS

interface News {
  id: number;
  title: string;
  preview_image_url: string;
  content: string;
}

const NewsDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [news, setNews] = useState<News | null>(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(API_ENDPOINTS.getNewsById(Number(id)));
        setNews(response.data);
      } catch (error) {
        console.error("Ошибка при загрузке новости:", error);
      }
    };

    fetchNews();
  }, [id]);

  if (!news) {
    return <div>Загрузка...</div>;
  }

  return (
    <div className="news-detail-container">
      <h1 className="news-detail-title">{news.title}</h1>
      <img
        className="news-detail-image"
        src={`/uploads/news_images/${news.preview_image_url}`}
        alt={news.title}
      />
      <div
        className="news-detail-content"
        dangerouslySetInnerHTML={{ __html: news.content }}
      />
    </div>
  );
};

export default NewsDetail;
