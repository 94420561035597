import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

const Menu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="menu">
      <button className="menu-toggle" onClick={toggleMenu}>
        &#9776;
      </button>
      <ul className={`menu-list ${isOpen ? 'open' : ''}`}>
        <li><Link to="/about">О Компании</Link></li>
        <li><Link to="/news">Новости</Link></li>
        <li><Link to="/subscribers">Абонентам</Link></li>
        <li><Link to="/contacts">Контакты</Link></li>
      </ul>
    </nav>
  );
};

export default Menu;