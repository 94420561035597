import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import './RegistrationForm.css';
import { API_ENDPOINTS } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

// Схема валидации с использованием Yup
const MAX_FILE_SIZE = 8 * 1024 * 1024; // 8 MB
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png", "application/pdf"];

const schema = yup.object().shape({
  full_name: yup.string().required('ФИО обязательно'),
  address: yup.string().required('Адрес обязателен'),
  email: yup.string().email('Неверный формат email').required('Email обязателен'),
  phone: yup.string().required('Телефон обязателен'),
  egrn: yup
    .mixed()
    .required('Выписка из ЕГРН обязательна')
    .test("required", "Необходимо выбрать файл", value => {
      return value && (value as FileList).length > 0;
    })
    .test("fileSize", "Файл слишком большой, максимум 8 МБ", value => {
      return value && (value as FileList).length > 0 ? (value as FileList)[0].size <= MAX_FILE_SIZE : true;
    })
    .test("fileFormat", "Поддерживаются только форматы: JPG, PNG, GIF, PDF", value => {
      return value && (value as FileList).length > 0 ? SUPPORTED_FORMATS.includes((value as FileList)[0].type) : true;
    }),
  passport: yup
    .mixed()
    .required('Паспорт обязателен')
    .test("required", "Необходимо выбрать файл", value => {
      return value && (value as FileList).length > 0;
    })
    .test("fileSize", "Файл слишком большой, максимум 8 МБ", value => {
      return value && (value as FileList).length > 0 ? (value as FileList)[0].size <= MAX_FILE_SIZE : true;
    })
    .test("fileFormat", "Поддерживаются только форматы: JPG, PNG, GIF, PDF", value => {
      return value && (value as FileList).length > 0 ? SUPPORTED_FORMATS.includes((value as FileList)[0].type) : true;
    }),
  password: yup.string()
    .required('Пароль обязателен')
    .min(8, 'Пароль должен содержать минимум 8 символов')
    .matches(/[A-Z]/, 'Пароль должен содержать минимум одну заглавную букву')
    .matches(/[a-z]/, 'Пароль должен содержать минимум одну строчную букву')
    .matches(/[0-9]/, 'Пароль должен содержать минимум одну цифру'),
  device_number: yup.string().required('Номер прибора учета обязателен'),
  calibration_certificate: yup
    .mixed()
    .required('Свидетельство о поверке обязательно')
    .test("required", "Необходимо выбрать файл", value => {
      return value && (value as FileList).length > 0;
    })
    .test("fileSize", "Файл слишком большой, максимум 8 МБ", value => {
      return value && (value as FileList).length > 0 ? (value as FileList)[0].size <= MAX_FILE_SIZE : true;
    })
    .test("fileFormat", "Поддерживаются только форматы: JPG, PNG, GIF, PDF", value => {
      return value && (value as FileList).length > 0 ? SUPPORTED_FORMATS.includes((value as FileList)[0].type) : true;
    }),
  agreement: yup.bool().oneOf([true], 'Необходимо принять пользовательское соглашение')
});

const RegistrationForm: React.FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      Object.keys(data).forEach(key => {
        if (data[key] instanceof FileList) {
          formData.append(key, data[key][0]);  // Отправка только первого файла из списка
        } else {
          formData.append(key, data[key]);
        }
      });

      // Логирование данных формы
      for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }

      // Отправка данных на сервер
      const response = await axios.post(API_ENDPOINTS.register, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Response:', response.data);
      setIsSubmitted(true);
    } catch (error) {
      // Обработка ошибок
      if (axios.isAxiosError(error)) {
        console.error('Axios error:', error.response ? error.response.data : error.message);
      } else {
        console.error('Unknown error:', error);
      }
      alert('Произошла ошибка при отправке данных.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="registration-form-container" style={{ backgroundImage: 'url(/kotelnya.png)' }}>
      {isSubmitted ? (
        <div className="success-message">
          <h2>Ваши документы отправлены на проверку</h2>
          <p>Ожидайте подтверждения от модератора. Вам придет письмо на указанную электронную почту с результатом проверки.</p>
        </div>
      ) : (
        <form className="registration-form" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label>ФИО:</label>
            <input {...register('full_name')} />
            {errors.full_name && <p>{errors.full_name.message}</p>}
          </div>
          <div>
            <label>Адрес:</label>
            <select {...register('address')}>
              <option value="">Выберите адрес</option>
              <option value="Химки, адрес 1">Химки, адрес 1</option>
              <option value="Химки, адрес 2">Химки, адрес 2</option>
              <option value="Химки, адрес 3">Химки, адрес 3</option>
            </select>
            {errors.address && <p>{errors.address.message}</p>}
          </div>
          <div>
            <label>Email:</label>
            <input {...register('email')} />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
          <div>
            <label>Пароль:</label>
            <div style={{ position: 'relative' }}>
              <input
                type={passwordVisible ? 'text' : 'password'}
                {...register('password')}
              />
              <FontAwesomeIcon
                icon={passwordVisible ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                  color: 'gray',
                }}
              />
            </div>
            {errors.password && <p>{errors.password.message}</p>}
          </div>
          <div>
            <label>Телефон:</label>
            <input {...register('phone')} />
            {errors.phone && <p>{errors.phone.message}</p>}
          </div>
          <div>
            <label>Выписка из ЕГРН:</label>
            <input type="file" {...register('egrn')} accept="image/*,application/pdf" />
            {errors.egrn && <p>{errors.egrn.message}</p>} {/* Вывод ошибки для egrn */}
          </div>
          <div>
            <label>Паспорт:</label>
            <input type="file" {...register('passport')} accept="image/*,application/pdf" />
            {errors.passport && <p>{errors.passport.message}</p>} {/* Вывод ошибки для passport */}
          </div>
          <div>
            <label>Номер прибора учета ГВС:</label>
            <input {...register('device_number')} />
            {errors.device_number && <p>{errors.device_number.message}</p>}
          </div>
          <div>
          <label>Свидетельство о поверке прибора учета:</label>
          <input type="file" {...register('calibration_certificate')} accept="image/*,application/pdf" />
          {errors.calibration_certificate && <p>{errors.calibration_certificate.message}</p>} {/* Вывод ошибки для calibration_certificate */}
        </div>
          <div className="agreement">
            <label>Согласен с пользовательским соглашением</label>
            <input type="checkbox" {...register('agreement')} />
            {errors.agreement && <p>{errors.agreement.message}</p>}
          </div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Отправка...' : 'Отправить'}
          </button>
        </form>
      )}
    </div>
  );
};

export default RegistrationForm;
