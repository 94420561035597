import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { API_ENDPOINTS } from '../config';
import './ResetPassword.css';

// Определяем схему валидации пароля с использованием Yup
const schema = yup.object().shape({
  password: yup.string()
    .required('Пароль обязателен')
    .min(8, 'Пароль должен содержать минимум 8 символов')
    .matches(/[A-Z]/, 'Пароль должен содержать минимум одну заглавную букву')
    .matches(/[a-z]/, 'Пароль должен содержать минимум одну строчную букву')
    .matches(/[0-9]/, 'Пароль должен содержать минимум одну цифру'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), undefined], 'Пароли должны совпадать') // Используем undefined вместо null
    .required('Подтверждение пароля обязательно'),
});

const NewPassword: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Инициализация useForm с использованием yupResolver для валидации
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  // Извлекаем токен из query-параметра
  const query = new URLSearchParams(location.search);
  const token = query.get('token');

  if (!token) {
    return <div>Ошибка: отсутствует токен для сброса пароля.</div>;
  }

  const onSubmit = async (data: { password: string; confirmPassword: string }) => {
    setIsSubmitting(true);

    // Выводим данные в консоль перед отправкой запроса
    console.log("Отправка данных на сервер:", { token, password: data.password });

    try {
      await axios.post(API_ENDPOINTS.resetPasswordConfirm, {
        token,
        password: data.password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setMessage('Пароль успешно изменен. Перенаправление...');
      setTimeout(() => navigate('/login'), 3000); // Перенаправление через 3 секунды
    } catch (err) {
      const error = err as AxiosError;
      if (error.response && error.response.status === 400) {
        setMessage('Неверный или истекший токен.');
      } else {
        setMessage('Ошибка при сбросе пароля. Пожалуйста, попробуйте еще раз.');
      }
      setIsSubmitting(false);
    }
  };

  return (
    <div className="reset-password-container" style={{ backgroundImage: 'url(/kotelnya.png)' }}>
      <form className="reset-password-form" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <label>Новый пароль:</label>
          <input
            type="password"
            {...register('password')}
            required
            disabled={isSubmitting}
          />
          {errors.password && <p className="error-message">{errors.password.message}</p>}
        </div>
        <div>
          <label>Подтвердите пароль:</label>
          <input
            type="password"
            {...register('confirmPassword')}
            required
            disabled={isSubmitting}
          />
          {errors.confirmPassword && <p className="error-message">{errors.confirmPassword.message}</p>}
        </div>
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Отправка...' : 'Сбросить пароль'}
        </button>
        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

export default NewPassword;
