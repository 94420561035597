import React, { useState } from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../config';
import './ResetPassword.css';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(API_ENDPOINTS.resetPassword, { email });
      setMessage('Проверьте свою почту для дальнейших инструкций.');
      setIsSubmitting(false);
    } catch (error) {
      setMessage('Пользователь с текущей почтой не зарегистрирован');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="reset-password-container" style={{ backgroundImage: 'url(/kotelnya.png)' }}>
      {message === 'Проверьте свою почту для дальнейших инструкций.' ? (
        <div className="success-message">
          <p>{message}</p>
        </div>
      ) : (
        <form className="reset-password-form" onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isSubmitting} // Блокировка поля ввода при отправке
            />
          </div>
          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Отправка...' : 'Отправить'}
          </button>
          {message && <p className="message">{message}</p>}
        </form>
      )}
    </div>
  );
};

export default ResetPassword;
